<template>
  <div id="static">
    <div class="title">Additional Terms and Conditions; EULAs</div>
    <div class="content">
      <p style="text-align: left">
        When you use G2A Pay services provided by G2A.COM Limited (hereinafter referred to as the
        "G2A Pay services provider") to make a purchase on our website, responsibility over your
        purchase will first be transferred to G2A.COM Limited before it is delivered to you. G2A Pay
        services provider assumes primary responsibility, with our assistance, for payment and
        payment related customer support. The terms between G2A Pay services provider and customers
        who utilize services of G2A Pay are governed by separate agreements and are not subject to
        the Terms on this website.
      </p>
      <p style="text-align: left">
        <br />With respect to customers making purchases through G2A Pay services provider checkout,
        (i) the Privacy Policy of G2A Pay services provider shall apply to all payments and should
        be reviewed before making any purchase, and (ii) the G2A Pay services provider Refund Policy
        shall apply to all payments unless notice is expressly provided by the relevant supplier to
        buyers in advance. In addition the purchase of certain products may also require shoppers to
        agree to one or more End-User License Agreements (or "EULAs") that may include additional
        terms set by the product supplier rather than by Us or G2A Pay services provider. You will
        be bound by any EULA that you agree to.
      </p>
      <p style="text-align: left">
        <br />We and/or entities that sell products on our website by using G2A Pay services are
        primarily responsible for warranty, maintenance, technical or product support services for
        those Products. We and/or entities that sell products on our website are primarily
        responsible to users for any liabilities related to fulfillment of orders, and EULAs entered
        into by the End-User Customer. G2A Pay services provider is primarily responsible for
        facilitating your payment.
      </p>
      <p style="text-align: left">
        <br />You are responsible for any fees, taxes or other costs associated with the purchase
        and delivery of your items resulting from charges imposed by your relationship with payment
        services providers or the duties and taxes imposed by your local customs officials or other
        regulatory body.
      </p>
      <p style="text-align: left">
        <br /><strong
          >For customer service inquiries or disputes, You may contact us by email at
        </strong>
        <a href="mailto:support@la2dream.com" target="_blank">support@la2dream.com</a>
      </p>
      <p style="text-align: left">
        <br /><strong
          >Questions related to payments made through G2A Pay services provider payment should be
          addressed to
        </strong>
        <a href="mailto:support@g2a.com" target="_blank">support@g2a.com</a>
      </p>
      <p style="text-align: left">
        <br />Where possible, we will work with You and/or any user selling on our website, to
        resolve any disputes arising from your purchase.
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'G2AAgreement',
};
</script>

<style scoped>
.title {
  text-align: center;
}

p {
  font-size: 1.2em;
}
</style>
